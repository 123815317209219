import http from '../api/http-common';

class paymentsService {
	// */routes/payments.js
	getPaymentsKey() {
		return http.get('/payments/index');
	}
	// */routes/productCleanup.js
	deleteCancelPayments(data) {
		return http.delete(`/cancelPayment/${data}`);
	}
}

export default new paymentsService();
